import classNames from 'classnames/bind';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { routes } from '@routes';

import styles from './Link.module.scss';

interface IProps extends React.HTMLAttributes<HTMLAnchorElement> {
  id?: string;
  testId?: string;
  href: string;
  activeClassName?: string;
  isActive?: boolean;
  download?: boolean;
  external?: boolean;
  scrollToTop?: boolean;
  noIcon?: boolean;
  label?: string;
  rel?: string;
}

const cx = classNames.bind(styles);

export const Link: React.FC<IProps> = ({
  id,
  testId,
  href,
  title,
  className,
  activeClassName,
  label,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onBlur,
  scrollToTop,
  rel,
  download = false,
  external = false,
  isActive = false,
  children,
}) => {
  const router = useRouter();

  const linkProps = {
    id,
    title,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    download,
    className: cx('link', className, {
      [activeClassName ?? '']:
        isActive || (isActive === undefined && router && router.pathname === href),
    }),
    target: external || download ? '_blank' : undefined,
    rel: external || download ? ['noopener', 'noreferrer', rel].filter(Boolean).join(' ') : rel,
  };

  const isInternalRoute = Object.values(routes).find(route => route.includes(href));

  return (
    <NextLink key={href} href={href} prefetch={false} scroll={scrollToTop}>
      <a
        {...linkProps}
        aria-label={label}
        data-testid={testId}
        onFocus={event => {
          if (isInternalRoute && router) {
            void router.prefetch(href);
          }
          if (onFocus) {
            onFocus(event);
          }
        }}
        onMouseEnter={event => {
          if (isInternalRoute && router) {
            void router.prefetch(href);
          }
          if (onMouseEnter) {
            onMouseEnter(event);
          }
        }}
      >
        {children}
      </a>
    </NextLink>
  );
};
